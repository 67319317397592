import React from "react"
import Layout from "../components/layout.js"
import SEO from "../components/seo.js"
import { data } from "../data/siteData.js"

import {
  HeroHeader,
  LandingAJob,
  Testimonial1,
  RespondingHeader,
  ResponsiveIndustry,
  Quotes,
  ResponsiveAbility,
  ResponsiveLife,
  CTA,
  BeginCourseForm,
} from "../components/page-components"

const {
  // header
  homeTitle,
  homeHeader,
  homeSubheader,
  homeInfo,
  homeBtnText,
  homeBtnLink,
  homeHeroImg,

  // landing a job
  homeLandingAJobHeader,
  homeLandingAJobInfo,
  homePhases,

  // Testimonial 1
  testimonial1,
  cite1,
  testimonialImg1,

  // Begin Course Form (no props)

  // Real World Needs
  respondingHeader,
  respondingSubheader,
  respondingDescription,

  // Responsive Industry
  industryHeader,
  industryInfo,
  industryImg,

  // Quotes
  quotes,

  // Responsive Ability
  abilityHeader,
  abilityDescription,
  abilityDesktopImg,
  abilityMobileImg,
  abilityBullets,

  // Responsive Life
  lifeHeader,
  lifeDescription,
  lifeTestimonial,
  lifeCite,
  lifeImg,
  lifeCourse,
  lifeBullets,

  // CTA
  ctaHeader,
  ctaSubHeader,
  ctaBtnText,
  ctaLink,
  ctaCourses,
} = data

export default function IndexPage() {
  return (
    <Layout>
      <SEO title="Veterans @ V School" />
      <HeroHeader
        header={homeHeader}
        logo={""}
        link={homeBtnLink}
        btnText={homeBtnText}
        sub={homeInfo}
        heroImg={homeHeroImg}
        info={homeSubheader}
        title={homeTitle}
      />
      <LandingAJob
        header={homeLandingAJobHeader}
        sub={homeLandingAJobInfo}
        phases={homePhases}
      />
      <Testimonial1
        testimonial={testimonial1}
        cite={cite1}
        testimonialImg={testimonialImg1}
      />

      <BeginCourseForm />

      <RespondingHeader
        header={respondingHeader}
        subHeader={respondingSubheader}
        description={respondingDescription}
      />

      <ResponsiveIndustry
        header={industryHeader}
        info={industryInfo}
        img={industryImg}
      />

      <Quotes quotes={quotes} />

      <ResponsiveAbility
        header={abilityHeader}
        description={abilityDescription}
        desktopImg={abilityDesktopImg}
        mobileImg={abilityMobileImg}
        bullets={abilityBullets}
      />

      <ResponsiveLife
        header={lifeHeader}
        description={lifeDescription}
        testimonial={lifeTestimonial}
        cite={lifeCite}
        img={lifeImg}
        course={lifeCourse}
        bullets={lifeBullets}
      />

      <CTA
        header={ctaHeader}
        subHeader={ctaSubHeader}
        btnText={ctaBtnText}
        link={ctaLink}
        courses={ctaCourses}
      />
    </Layout>
  )
}
